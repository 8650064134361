import React, { useEffect } from "react";

function MentionsLegales() {
  useEffect(() => {
    // Empêcher le scrolling lorsque le composant est monté
    document.body.style.overflow = "hidden";

    // Rétablir le scrolling lorsque le composant est démonté
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="bg-gray-900 rounded-md p-4 w-full h-screen  text-left mx-auto my-10 overflow-auto">
      <div className="flex justify-center mt-44 ">
        <button
          className="text-gray-400 hover:text-white focus:outline-none "
          onClick={() => window.history.back()}
        >
          Retour au site
        </button>
      </div>
      <div className="flex flex-col items-start lg:mx-56 justify-center text-left lg:my-24 my-8 font-trebuc text-xl text-gray-300">
        <p className=" mb-10 font-trebuc uppercase custom-color font-bold ">
          Mentions Légales
        </p>
        <p className=" font-trebuc mb-2 font-semibold custom-color">
          PUBLICATION
        </p>
        <p className=" mb-6 text-left">
          Le présent site est édité par : <br />
          Marine Brunière – Avocat au Barreau de LYON
          <br />
          89 rue de Créqui 69006 <br />
          LYON <br />
          Mail : marinebruniere.avocat@gmail.com
          <br />
          Tel : 06 43 44 23 80
          <br />
          SIRET : 89263895800017
        </p>

        <p className=" mb-2 custom-color">CONCEPTION</p>
        <p className="  mb-6">
          Directeur de publication/ Développement & optimisation : Arnaud
          CHAMPETIER <br />
          06 68 00 81 48 <br />
          cours Emile Zola 69100 Villeurbanne{" "}
        </p>

        <p className="  mb-2 font-semibold custom-color">HÉBERGEMENT</p>
        <p className="  mb-8">
          Société OVH Tel : 1007 <br />
          SAS au capital de 10 069 020 € <br />
          RCS Lille Métropole 424 761 419 00045 Code APE 2620Z <br />
          N° TVA : FR 22 424 761 419 <br />
          Siège social : 2 rue Kellermann – 59100 Roubaix – France <br />
          Directeur de la publication : Octave KLABA <br />
          <br />
          OVH SAS est une filiale de la société OVH Groupe SAS, société
          immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue
          Kellermann, 59100 Roubaix.
        </p>

        <p className="  mb-2 font-semibold custom-color">COOKIES</p>
        <p className="mb-6">
          Notre site n'utilise pas de cookies à des fins de suivi ou d'analyse.
          Votre expérience de navigation ne sera pas affectée par la
          désactivation des cookies.
        </p>

        <p className="  mb-2 font-semibold custom-color">
          PROPRIETE INTELLECTUELLE{" "}
        </p>
        <p className=" mb-6">
          Toute reproduction ou représentation totale ou partielle de ce site
          par quelque procédé que ce soit, sans autorisation expresse, est
          interdite et constituerait une contrefaçon sanctionnée par les
          articles L.335-2 et suivants du Code de la propriété intellectuelle.
          Toute reproduction totale ou partielle des logos, images, scripts,
          code HTML, balises meta effectuée à partir des éléments du site sans
          notre autorisation expresse est prohibée au sens de l'article L.713-2
          du Code de la propriété intellectuelle.'
        </p>

        <p className=" mb-2 font-semibold custom-color">
          PROTECTION DE VOS INFORMATIONS{" "}
        </p>
        <p className=" mb-6">
          Les données nominatives enregistrées sur ce site sont stockées et
          utilisées conformément à la loi du 6 janvier 1978 relative à
          l'informatique et aux libertés et sont réservées à l'usage exclusif de
          Marine Brunière. Les utilisateurs de ce site disposent d'un droit
          d'accès, de rectification et de suppression des données.
        </p>

        <p className=" mb-2 font-semibold custom-color">
          DONNÉES PERSONNELLES ET LIENS EXTERNES
        </p>
        <p className=" mb-6">
          Aucune donnée personnelle n’est collectée par ce site internet via les
          cookies. Veuillez noter que notre site peut contenir des liens vers
          d'autres sites web. Nous tenons à vous informer que notre politique de
          confidentialité ne s'applique pas à ces sites tiers, et nous déclinons
          toute responsabilité quant à leur contenu ou à leurs pratiques de
          confidentialité. Si vous choisissez de télécharger des documents
          depuis notre site, veuillez noter que cela peut entraîner la collecte
          de données à des fins statistiques. Ces données sont traitées de
          manière anonyme et ne permettent pas une identification personnelle.
        </p>
      </div>
    </div>
  );
}

export default MentionsLegales;
