import React from "react";
import LogoKevinWhite from "../assets/logoMBtest.png";
import iconeLK from "../assets/iconeLK.png";
import Map from "./Map.jsx";

function Contact() {
  return (
    <div className="flex flex-col">
      <div
        id="contact"
        name="contact"
        className={`w-full min-h-screen flex flex-col lg:flex-row items-center justify-center bg-gray-900 `}
      >
        <div className="mt-8 max-w-screen-lg lg:w-1/3 mx-auto  p-4 flex flex-col justify-center w-full h-full ">
          <div className="pb-8 custom-color">
            <p
              className={`md:text-4xl text-3xl font-trebuc font-bold uppercase inline border-b-4 custom-border p-2`}
            >
              Contact
            </p>
            <p className={`py-6 text-white font-trebuc text-xl  `}>
              Remplissez le formulaire pour me faire part de votre situation
            </p>
          </div>
          <div className="flex justify-center items-center">
            <form
              action="https://getform.io/f/3906908a-36a5-4e57-a715-7a0dacc7e282"
              method="POST"
              className="flex flex-col w-full md:w-full font-trebuc "
            >
              <input
                type="text"
                name="name"
                placeholder="Entrez votre nom"
                className="p-2   bg-transparent border-2 rounded-md  focus:outline-none mb-4"
              />
              <input
                type="text"
                name="mail"
                placeholder="Entrez votre mail"
                className="p-2  bg-transparent border-2 rounded-md text-white focus:outline-none mb-4"
              />
              <input
                type="text"
                name="phone"
                placeholder="Entrez votre téléphone"
                className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none mb-4"
              />
              <input
                type="text"
                name="phone"
                placeholder="Objet de votre demande"
                className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none mb-4"
              />

              <textarea
                name="message"
                placeholder="Entrez votre message"
                rows="10"
                className="p-2 font-trebuc bg-transparent border-2 rounded-md text-white focus:outline-none mb-4"
              ></textarea>
              <button
                className="text-gray-800 font-trebuc custom-button px-12 py-4 
             mx-auto flex items-center rounded-md hover:scale-110 duration-300 md:text-2xl"
              >
                Envoyer{" "}
              </button>
            </form>
          </div>
        </div>
        <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center md:justify-end h-full px-4 md:flex-row ">
          <div className="flex flex-col md:mx-12 mx-auto md:flex md:flex-col items-center md:items-end md:justify-end text-white ">
            <div
              className=" bg-gray-900 opacity-80 flex flex-col justify-center md:px-4 md:py-12 mt-8 md:mt-0 z-10 w-full
           sm:w-3/4 text-center md:w-full md:h-2/3 rounded-xl shadow-2xl mx-auto"
            >
              <img
                src={LogoKevinWhite}
                alt="Logo Kevin"
                className="md:h-80 md:w-80 h-64 w-64 py-4 px-4 mx-auto cursor-pointer"
              />

              <div className="text-white text-lg md:text-2xl font-trebuc font-light mb-0">
                <p className="mb-2">Entrepreneure individuelle</p>
                <p className="mb-2">
                  Tel : <strong className="font-bold">06 43 44 23 80</strong>
                </p>
                <p className="mb-2">
                  Courriel :{" "}
                  <strong className="font-bold">
                    contact@bruniere-avocat.com
                  </strong>
                </p>
                <p>Adresse : 89 rue de Créqui 69006 Lyon</p>
                <a
                  href="https://www.linkedin.com/in/marine-bruni%C3%A8re-337551135/?originalSubdomain=fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="w-12 h-12 cursor-pointer items-center justify-center mx-auto mt-6 mb-4 md:mb-0"
                    src={iconeLK}
                    alt="iconeCV"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 ">
        <Map />
      </div>
    </div>
  );
}

export default Contact;
