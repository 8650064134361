import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon from "../assets/markergreen.png";
import "leaflet/dist/leaflet.css";

const Map = () => {
  const position = [45.7695239, 4.8461886]; // Coordonnées pour 89 rue de Créqui, 69006 Lyon

  const icon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
  });

  return (
    <div className="lg:w-1/3 w-full mx-auto h-96 relative rounded-xl mb-24 md:mb-24 ">
      <MapContainer
        center={position}
        zoom={16}
        className="w-full h-full leaflet-pane "
      >
        <TileLayer
          attribution='&amp;copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={icon}>
          <Popup>
            89 rue de Créqui <br /> 69006 Lyon
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
