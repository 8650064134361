import React from "react";
import bgCab from "../assets/bgcab.jpg";

function DomaineCompetences2() {
  const domaines = [
    "Discipline et déontologie de la fonction publique",
    "Accident de service et maladie professionnelle",
    "Carrière des agents publics",
    "Rémunération des agents publics",
    "Protection fonctionnelle",
    "Droit des agents contractuels",
    "Harcèlement moral",
    "Aide à la réalisation d’enquête administrative",
    "Accompagnement en médiation",
  ];

  return (
    <div
      className="custom-color min-h-[75vh] bg-gray-300 relative overflow-hidden"
      id="domaines"
    >
      <div
        className="absolute inset-0 bg-cover bg-no-repeat bg-center bg-fixed black-and-white mt-42 opacity-70 bg-image"
        style={{
          backgroundImage: `url(${bgCab})`,
        }}
      ></div>

      <div className="relative z-10 h-auto">
        <div className="w-full text-center p-4 md:p-10 bg-gray-900">
          <h1
            className="text-3xl md:p-0 p-6 md:text-4xl font-trebuc md:mb-4 custom-color
            uppercase relative cursor-pointer transition-all duration-500
            before:content-[''] before:absolute before:bottom-[-3px] before:left-1/2 before:-translate-x-1/2
            before:w-0 before:h-0.5 before:rounded-full before:opacity-0 before:transition-all before:duration-500
            before:bg-gradient-to-r before:from-gray-200 before:via-gray-800 before:to-gray-200 hover:before:w-full hover:before:opacity-100"
          >
            Domaines de Compétences{" "}
          </h1>
        </div>
        <div className="mx-4 md:mx-12 my-auto mb-24">
          <div className="gap-4 md:gap-6 rounded-xl p-4 md:p-10 text-justify bg-gray-900 opacity-95 relative bottom-2 top-8 mt-12 md:mt-12 mb-12 md:mb-24 xl:mx-96">
            <p className="md:text-xl font-trebuc text-gray-300 text-justify my-2 md:my-2  ">
              Le champ d’intervention de Maître Marine BRUNIERE couvre les trois
              versants de la fonction publique (État, territoriale et
              hospitalière). Elle a vocation à traiter toutes les problématiques
              rencontrées dans les domaines suivants :
            </p>
            {domaines.map((domaine, index) => (
              <p
                key={index}
                className={`text-gray-400 uppercase font-bold rounded-xl shadow-lg bg-gray-950 px-2 md:px-4 py-2 md:py-4 flex items-center justify-center cursor-pointer text-center font-trebuc text-md md:text-xl mt-2 md:mt-4 ${
                  index === domaines.length - 1 ? "col-span-2" : ""
                }`}
              >
                {domaine}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DomaineCompetences2;
